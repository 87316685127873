import { useEffect, useRef, useState } from 'react';

import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '@/components/atoms/Button/Button/Button';
import Edit from '@/components/atoms/Icons/Edit';
import TextAreaAsInput from '@/components/atoms/Input/TextAreaAsInput';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { AmendPayload } from '@/models/tryIt';

import styles from './AmendWrapper.module.scss';

const muiStyles = {
  accordionRoot: {
    boxSizing: 'border-box',
    backgroundColor: 'var(--surface-primary-white)',
    border: '1px solid transparent',
    boxShadow: 'none',
    transitionDuration: '200ms',
    scrollMarginTop: 'var(--space-16)',
    cursor: 'default',
    padding: 0,

    '&::before': {
      height: '0',
    },
  },

  detailsRoot: {
    padding: 0,
  },
};

interface Props {
  children?: React.ReactNode;
  amendPayload: AmendPayload;
  position?: 'left' | 'right';
}

interface Form {
  correction: string;
}
export const AmendWrapper = ({
  children = <></>,
  amendPayload,
  position = 'right',
}: Props) => {
  const features = useFeatureFlag();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>();

  const [show, setShow] = useState(false);

  //TODO update this when endpoint is ready
  const onSubmit = (data: Form) => {
    const payload = {
      ...amendPayload,
      correction: data.correction,
    };
    // eslint-disable-next-line no-console
    console.log({ payload });
  };

  const handleShowClick = () => {
    if (!show) {
      setShow(true);
      if (inputRef.current) {
        setTimeout(() => {
          inputRef.current.focus();
        }, 300);
      }
    }
  };

  useEffect(() => {
    if (show && inputRef.current) {
      setTimeout(() => {
        inputRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 400);
    }
  }, [show]);

  if (!amendPayload || !features.va_feedback) {
    return children;
  }
  return (
    <div>
      {children}
      <div className={styles.container}>
        <MuiAccordion sx={muiStyles.accordionRoot} expanded={show}>
          <div className={cn(styles.amendButton, styles[position])}>
            <Button
              variant="tertiary"
              size="small"
              onClick={handleShowClick}
              noGutters
            >
              {t('try_it.amend')}
              <Edit color="var(--cta-default-blue)" />
            </Button>
          </div>
          <AccordionDetails sx={muiStyles.detailsRoot}>
            <div className={styles.form}>
              <TextAreaAsInput
                id="correction"
                name="correction"
                label={
                  amendPayload.task === 'standalone'
                    ? t('try_it.correct_standalone')
                    : t('try_it.correct_answer')
                }
                size="large"
                rows={6}
                height="auto"
                autofocus
                {...register('correction', {
                  required: true,
                })}
                ref={(e) => {
                  register('correction').ref(e); // set ref from register
                  inputRef.current = e; // keep the local ref for custom use
                }}
                trimValue
                error={!!errors.correction}
              />
              <Typography
                variant="label-regular"
                color="var(--text-default-gray-light)"
              >
                {t('try_it.upon_approval')}
              </Typography>
              <div className={styles.buttonsContainer}>
                <Button variant="tertiary" onClick={() => setShow(false)}>
                  {t('common.cancel')}
                </Button>
                <Button variant="secondary" onClick={handleSubmit(onSubmit)}>
                  {t('common.submit')}
                </Button>
              </div>
            </div>
          </AccordionDetails>
        </MuiAccordion>
      </div>
    </div>
  );
};
