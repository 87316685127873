import { useMemo } from 'react';

import {
  InfiniteData,
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { broadcastsEndpoints as endpoints } from '@/api/endpoints';
import { callDelete, callGet, callPost, callPut } from '@/api/fetcher';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import {
  Broadcast,
  Broadcasts,
  PartialBroadcast,
  WhatsappTemplates,
} from '@/modules/broadcast/models';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountId } from '@/redux/session/selectors';

import { selectBroadcast, selectBroadcastDeskId } from '../redux/selectors';

export const API = Object.freeze({
  listBroadcasts: async (
    cursor?: { cursor: string },
    filter?: string
  ): Promise<Broadcasts> =>
    callGet(endpoints.broadcasts(cursor.cursor, filter)),

  getBroadcast: async (broadcastId: string): Promise<Broadcast> =>
    callGet(endpoints.broadcast(broadcastId)),

  createBroadcast: async (
    newBroadcast: Partial<Broadcast>
  ): Promise<Broadcast> => callPost(endpoints.broadcasts(), newBroadcast),

  updateBroadcast: async ({
    broadcast_id,
    ...broadcast
  }: PartialBroadcast): Promise<Broadcast> =>
    callPut(endpoints.broadcast(broadcast_id), broadcast),

  deleteBroadcast: async (
    broadcastId: string,
    force?: boolean
  ): Promise<Broadcast> => {
    const queryParams = force ? `?force=${force}` : '';
    return callDelete(`${endpoints.broadcast(broadcastId)}${queryParams}`);
  },
  whatsAppTemplates: (
    desk_id: string,
    integration_id: string
  ): Promise<WhatsappTemplates> =>
    callGet(endpoints.whatsAppTemplates(desk_id, integration_id)),
});

interface DeleteProps {
  broadcast_id: string;
  force?: boolean;
}

export const onBroadcastUpdated = (
  queryClient: QueryClient,
  broadcast: Broadcast,
  filter?: string
) => {
  queryClient.setQueryData<Broadcast>(
    [endpoints.broadcast(broadcast.broadcast_id)],
    (prev: Broadcast) => ({ ...prev, ...broadcast })
  );
  const queryKey = [endpoints.broadcasts, filter, broadcast.account_id];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData(queryKey, (prev: InfiniteData<Broadcasts>) => {
      if (!prev) return prev;

      return {
        ...prev,
        pages: prev.pages.map((page) => ({
          ...page,
          broadcasts: page.broadcasts.map((acc) =>
            acc.broadcast_id === broadcast.broadcast_id ? broadcast : acc
          ),
        })),
      };
    });
  }
};

export const onBroadcastCreated = (
  queryClient: QueryClient,
  broadcast: Broadcast,
  filter?: string
) => {
  const queryKey = [endpoints.broadcasts, filter, broadcast.account_id];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData(queryKey, (prev: InfiniteData<Broadcasts>) => {
      if (!prev) return prev;

      const newPages = prev.pages.map((page, index) => {
        if (index === 0) {
          return {
            ...page,
            broadcasts: [
              broadcast,
              ...page.broadcasts.filter(
                (acc) => acc.broadcast_id !== broadcast.broadcast_id
              ),
            ],
          };
        }
        return page;
      });

      return {
        ...prev,
        pages: newPages,
      };
    });
  }
};

export const onBroadcastRemoved = (
  queryClient: QueryClient,
  account_id: string,
  broadcast_id: string,
  filter?: string
) => {
  const queryKey = [endpoints.broadcasts, filter, account_id];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData(queryKey, (prev: InfiniteData<Broadcasts>) => {
      if (!prev) {
        return prev;
      }
      return {
        ...prev,
        pages: prev.pages.map((page) => ({
          ...page,
          broadcasts: page.broadcasts.filter(
            (acc) => acc.broadcast_id !== broadcast_id
          ),
        })),
      };
    });
  }

  queryClient.removeQueries({ queryKey: [endpoints.broadcast(broadcast_id)] });
};

export const useBroadcasts = (broadcastId?: string, filter?: string) => {
  const queryClient = useQueryClient();
  const accountId = useSelector(selectAccountId);
  const deskId = useSelector(selectBroadcastDeskId);
  const draftBroadcast = useSelector(selectBroadcast);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const canRead = useSelector((state: RootState) =>
    getPermissions(state, 'broadcasts', actions.READ)
  );

  const {
    data: broadcasts,
    status: listStatus,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: [endpoints.broadcasts, filter, accountId],
    queryFn: async ({ pageParam }) => {
      return API.listBroadcasts(pageParam, filter);
    },
    getNextPageParam: ({ pagination }) => {
      if (pagination.has_more) {
        return { cursor: pagination.next_cursor, filter };
      }
      return undefined;
    },
    initialPageParam: {} as { cursor: string | undefined },
    enabled: !!accountId && canRead,
    refetchInterval: 7 * 1000 /* 7 seconds */,
  });

  const {
    data: broadcast,
    status: getStatus,
    isLoading: isBroadcastLoading,
  } = useQuery<Broadcast, Error>({
    queryKey: [endpoints.broadcast(broadcastId)],
    queryFn: () => API.getBroadcast(broadcastId),
    enabled: !!broadcastId && canRead && broadcastId !== 'draft',
    refetchInterval: 5 * 1000,
  });

  const { data: whatsAppTemplates, status: listwhatsAppTemplatesStatus } =
    useQuery<WhatsappTemplates, Error>({
      queryKey: [
        endpoints.whatsAppTemplates(deskId, draftBroadcast.integration_id),
      ],
      queryFn: () =>
        API.whatsAppTemplates(deskId, draftBroadcast.integration_id),
      enabled: Boolean(deskId) && !!draftBroadcast.integration_id,
    });

  const {
    mutate: createBroadcast,
    mutateAsync: createBroadcastAsync,
    status: createStatus,
  } = useMutation<Broadcast, Error, Partial<Broadcast>>({
    mutationFn: (broadcast) => API.createBroadcast(broadcast),
    onSuccess: (resp) => {
      onBroadcastCreated(queryClient, resp, filter);
      dispatch(
        addTemporalToast(
          'success',
          t('broadcasts.broadcast_created', { 0: resp.name })
        )
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const {
    mutate: updateBroadcast,
    mutateAsync: updateBroadcastAsync,
    status: updateStatus,
  } = useMutation<Broadcast, Error, PartialBroadcast>({
    mutationFn: API.updateBroadcast,
    onSuccess: (resp) => {
      onBroadcastUpdated(queryClient, resp, filter);
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });
  const { mutate: deleteBroadcast, status: deleteStatus } = useMutation<
    Broadcast,
    Error,
    DeleteProps
  >({
    mutationFn: ({ broadcast_id, force }) =>
      API.deleteBroadcast(broadcast_id, force),
    onSuccess: (resp) => {
      onBroadcastRemoved(queryClient, accountId, resp.broadcast_id, filter);
      dispatch(addTemporalToast('success', t('broadcasts.broadcast_deleted')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const flatBroadcasts = useMemo(
    () => broadcasts?.pages?.flatMap((page) => page.broadcasts),
    [broadcasts?.pages]
  );

  const broadcastNames = useMemo(
    () => flatBroadcasts?.map((b) => b.name.toLowerCase()),
    [flatBroadcasts]
  );
  return {
    broadcasts: flatBroadcasts,
    fetchNextPage,
    hasNextPage,
    broadcast,
    whatsAppTemplates: whatsAppTemplates?.templates,
    listwhatsAppTemplatesStatus,
    createStatus,
    getStatus,
    listStatus,
    updateStatus,
    createBroadcast,
    createBroadcastAsync,
    updateBroadcast,
    updateBroadcastAsync,
    deleteBroadcast,
    deleteStatus,
    broadcastNames,
    isBroadcastLoading,
  };
};
