import { AnalyticsBrowser, Context } from '@segment/analytics-next';
import { isbot } from 'isbot';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import { Account } from '@/models/account';
import { PageName, EventName, EventProps } from '@/models/segment';
import { Preferences, User } from '@/models/user';

let userIdentified = false;

const loadAnalytics = () => {
  if (window.SEGMENT_KEY && !isbot(navigator.userAgent)) {
    return AnalyticsBrowser.load({ writeKey: window.SEGMENT_KEY });
  }

  // Return a mock to avoid sending bot events to segment.
  return {
    page: console.debug,
    track: console.debug,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    group: async (): Promise<any> => console.debug,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    identify: async (): Promise<any> => console.debug,
  };
};

const analytics = loadAnalytics();

export const pageView = (pageName: PageName, properties?: EventProps) =>
  analytics.page('UI', pageName, {
    ...properties,
    slug: window.X_MOVEO_ACCOUNT_SLUG,
  });

/**
 * Tracks the account as a group
 * See https://segment.com/docs/connections/spec/group/
 */
export const trackAccount = (account: Account): Promise<Context> =>
  analytics.group(account.account_id, {
    name: account.name,
    created: account.created,
    plan_id: account.plan_id,
    slug: account.slug,
    stripe_customer_id: account.stripe_customer_id,
    current_period_ends: account.current_period_ends,
  });

export const identifyUser = async (
  user: User,
  preferences: Preferences,
  trackUserInLogRocket: boolean
): Promise<Context> => {
  // Segment identify
  const context = await analytics.identify(user.user_id, {
    email: user.email,
    name: user.name,
    created: user.created,
    language: preferences?.language || 'en',
    timezone: preferences?.timezone,
    job: preferences?.job,
    industry: preferences?.industry as string,
  });

  // LogRocket identify
  if (window.DEPLOY_ENV === 'prod' && !userIdentified && trackUserInLogRocket) {
    LogRocket.init('hohiqf/moveo-prod');
    setupLogRocketReact(LogRocket);
    userIdentified = true;
    LogRocket.identify(user.user_id, {
      name: user.name,
    });

    // Map segment with logRocket
    LogRocket.getSessionURL(function (sessionURL) {
      analytics.track('LogRocket', {
        sessionURL: sessionURL,
      });
    });
  }

  return context;
};

export const trackEvent = (name: EventName, properties?: EventProps) => {
  return analytics.track(name, {
    ...properties,
    // Add the account slug to all the events
    slug: window.X_MOVEO_ACCOUNT_SLUG,
  });
};
