import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { useMarkdownToHtml } from '@/hooks/useMarkdownToHtml';
import { AmendPayload } from '@/models/tryIt';
import { Standalone as StandaloneIcon } from '@/modules/TryIt/Icons/Standalone';
import { TriggerMessageIcon } from '@/modules/TryIt/Icons/TriggerMessageIcon';

import { AmendWrapper } from '../../Messages/AmendWrapper/AmendWrapper';

import styles from './DetailsSection.module.scss';

interface Props {
  standAloneQuestion?: string;
  userMessage: string;
  amendPayload: AmendPayload;
}

export const StandaloneWrapper = ({
  standAloneQuestion,
  userMessage,
  amendPayload,
}: Props) => {
  const { t } = useTranslation();
  const { html } = useMarkdownToHtml(standAloneQuestion);

  return (
    <div className={styles.step}>
      {standAloneQuestion ? (
        <>
          <div className={styles.title}>
            <TriggerMessageIcon />
            <Typography color="var(--text-default-gray)">
              {t('try_it.rephrased_by_the_virtual_agent')}
            </Typography>
          </div>
          <div className={cn(styles.details, styles.amendContainer)}>
            <Box className={styles.standalone}>
              <StandaloneIcon />
              <Typography
                component="span"
                dangerouslySetInnerHTML={{
                  __html: html,
                }}
              />
            </Box>
            <AmendWrapper amendPayload={amendPayload} position="left" />
          </div>
        </>
      ) : (
        <>
          <div className={styles.title}>
            <TriggerMessageIcon />
            <Typography
              variant="body-regular"
              color="var(--text-default-gray)"
              className={styles.triggerMessage}
              component="div"
            >
              {userMessage}
            </Typography>
          </div>
          <div className={styles.details}></div>
        </>
      )}
    </div>
  );
};
