import { useCallback, useState } from 'react';

import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import uniqBy from 'lodash/uniqBy';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Button from '@/components/atoms/Button/Button/Button';
import CollectionIcon from '@/components/atoms/Icons/Collection';
import Entities from '@/components/atoms/Icons/Entities';
import IntentIcon from '@/components/atoms/Icons/Intent';
import SearchIcon from '@/components/atoms/Icons/Search';
import UnknownIcon from '@/components/atoms/Icons/Unknown';
import IntentModal from '@/components/organisms/TryIt/IntentModal/IntentModal';
import { useAccount } from '@/hooks/useAccount';
import useBrains from '@/hooks/useBrains';
import { LogSessionContentRow } from '@/hooks/useLogs';
import { Collection } from '@/models/collections';
import { actions } from '@/models/permissions';
import { EventName } from '@/models/segment';
import { RootState } from '@/models/state';
import {
  AmendPayload,
  CollectionMessage,
  Entity,
  Fragment,
  TryIt,
  UserMessage,
} from '@/models/tryIt';
import { LogMessage } from '@/modules/analytics/models';
import { useDetailsSection } from '@/modules/TryIt/hooks/useDetailsSection';
import {
  selectIsTryItReplay,
  selectSelectedMessage,
  selectStandaloneQuestion,
  selectTryItSessionId,
} from '@/modules/TryIt/redux/selectors';
import { popModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectBrainId } from '@/redux/session/selectors';
import { trackEvent } from '@/segment/segment';

import { DocumentLine } from './CollectionDetailsSection/DocumentLine';
import { StandaloneWrapper } from './StandaloneWrapper';
import { StatusIcon } from '../../StatusIcon/StatusIcon';

import styles from './DetailsSection.module.scss';

type UnknownDetailsSectionProps = {
  content: LogSessionContentRow;
};

export type UnknownDetailsSectionContentProps = {
  nodes: TryIt['selectedMessage']['nodes'];
  index: number;
  entities: Entity[];
  collection: Collection;
  uniqueDatasources: Fragment[];
  slug: string;
  standAloneQuestion: string;
  messages?: LogMessage[];
  isReplay: boolean;
  canWrite: boolean;
  session_id?: string;
  activeBrainId: string;
};

export const UnknownDetailsSectionContent = ({
  nodes,
  index,
  entities,
  collection,
  uniqueDatasources,
  slug,
  standAloneQuestion,
  messages,
  isReplay,
  canWrite,
  session_id,
  activeBrainId,
}: UnknownDetailsSectionContentProps) => {
  const { t } = useTranslation();
  const tryItSessionId = useSelector(selectTryItSessionId);

  const dispatch = useDispatch();

  const intent =
    nodes[index]?.intents?.[0] ||
    (messages?.[index]?.message as UserMessage)?.intents?.[0];
  const intentName = intent?.intent;

  const userMessage = (nodes[index]?.messages[0] as CollectionMessage)?.text;
  const closeModal = useCallback(() => {
    dispatch(popModal());
  }, [dispatch]);

  const [expressionToTrain, setExpressionToTrain] = useState('');
  const showAddTrainingPhrase = isReplay && intent?.confidence !== 1;
  const [showIntentsModal, setShowIntentsModal] = useState(false);

  const { brain } = useBrains(activeBrainId);
  const amendPayload: AmendPayload = {
    request_id:
      nodes[index].request_id || nodes[index].nodes_stack[0]?.request_id,
    task: 'standalone',
    sessionId: session_id || tryItSessionId,
    language: brain?.language,
  };

  return (
    <Stack data-testid="newDebugLogs">
      {isReplay && showIntentsModal && (
        <IntentModal
          close={() => {
            setExpressionToTrain('');
            setShowIntentsModal(false);
          }}
          expression={expressionToTrain}
          brainId={activeBrainId}
        />
      )}

      <StandaloneWrapper
        standAloneQuestion={standAloneQuestion}
        userMessage={userMessage}
        amendPayload={amendPayload}
      />

      {entities.length > 0 && (
        <div className={styles.step}>
          <div className={styles.title}>
            <Entities />
            <Typography
              variant="body-regular"
              color="var(--text-default-gray)"
              component="div"
            >
              {t('try_it.entity', {
                count: entities?.length,
              })}{' '}
              {entities?.map((entity, index) => (
                <>
                  <Typography key={entity.value} variant="body-regular">
                    <NavLink
                      to={`/${slug}/brains/${activeBrainId}/entities/${entity.entity}`}
                      className={styles.link}
                      onClick={closeModal}
                    >
                      {entity.entity}
                    </NavLink>
                  </Typography>
                  {index < entities.length - 1 && ', '}
                </>
              ))}{' '}
              {t('try_it.matched')}
            </Typography>
          </div>
          <div className={styles.details}></div>
        </div>
      )}

      <div className={styles.step}>
        <div className={styles.title}>
          <IntentIcon color="var(--icon-default-gray)" />
          {intentName ? (
            <Typography
              variant="body-regular"
              color="var(--text-default-gray)"
              component="div"
            >
              {t('common.intent')}{' '}
              <Typography variant="body-regular">
                <NavLink
                  to={`/${slug}/brains/${activeBrainId}/intents/${intent?.intent}`}
                  className={styles.link}
                  onClick={closeModal}
                >
                  {intent?.intent}
                </NavLink>
              </Typography>{' '}
              {t('try_it.matched_with_confidence', {
                percentage: (intent?.confidence * 100).toFixed(0),
              })}
            </Typography>
          ) : (
            <Typography
              variant="body-regular"
              color="var(--text-default-gray)"
              component="div"
            >
              {t('try_it.no_intent_was_found')}
            </Typography>
          )}
        </div>

        <div className={styles.details}>
          {showAddTrainingPhrase && (
            <Button
              disabled={!canWrite}
              variant="tertiary"
              onClick={() => {
                setExpressionToTrain(userMessage);
                setShowIntentsModal(true);
                trackEvent(EventName.ClickAddTrainingPhrase, {
                  session_id,
                });
              }}
            >
              {t('try_it.details.add_training_phrase')}
            </Button>
          )}
        </div>
      </div>

      {collection && (
        <>
          <div className={styles.step}>
            <div className={styles.title}>
              <CollectionIcon size={16} />

              <Typography
                variant="body-regular"
                color="var(--text-default-gray)"
                component="div"
              >
                {t('collections.collection')}{' '}
                <NavLink
                  target="_blank"
                  rel="noopener noreferrer"
                  to={`/${slug}/collections/${collection?.collection_id}`}
                  className={styles.link}
                >
                  {collection?.name}
                </NavLink>{' '}
                {t('try_it.triggered')}
              </Typography>
            </div>
            <div className={styles.details}></div>
          </div>
          <div className={styles.step}>
            <div className={styles.title}>
              <SearchIcon />

              <Typography
                variant="body-regular"
                color="var(--text-default-gray)"
                component="div"
              >
                {t('try_it.document_retrieved', {
                  count: uniqueDatasources.length,
                })}
              </Typography>
            </div>
            <div className={styles.details}>
              {uniqueDatasources.length > 0 && (
                <ol className={styles.retrievedDocuments}>
                  {uniqueDatasources.map((datasource) => (
                    <DocumentLine
                      key={datasource.document_id}
                      collectionId={
                        nodes[index]?.collection?.collection_id ??
                        messages?.[index]?.collection_id
                      }
                      datasourceId={datasource.datasource_id}
                      documentId={datasource.document_id}
                    />
                  ))}
                </ol>
              )}
            </div>
          </div>
          <div className={styles.step}>
            <div className={styles.title}>
              <StatusIcon status="warning">
                <SearchIcon />
              </StatusIcon>

              <Typography
                variant="body-regular"
                color="var(--text-default-gray)"
                component="div"
              >
                {t('try_it.answer_not_found_in_collection')}
              </Typography>
            </div>
            <div className={styles.details}></div>
          </div>
        </>
      )}

      <div className={styles.step}>
        <div className={styles.title}>
          <UnknownIcon color="var(--icon-default-gray)" />

          <Typography
            variant="body-regular"
            color="var(--text-default-gray)"
            component="div"
          >
            {t('try_it.unknown_response_sent')}
          </Typography>
        </div>
        <div className={styles.details}></div>
      </div>
    </Stack>
  );
};

export const UnknownDetailsSection = ({
  content,
}: UnknownDetailsSectionProps) => {
  const standAloneQuestion = useSelector(selectStandaloneQuestion);
  const isReplay = useSelector(selectIsTryItReplay);
  const brainId = useSelector(selectBrainId);
  const selectedMessage = useSelector(selectSelectedMessage);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );
  const { session_id, brain_id: logBrainId } = content || {};

  const activeBrainId = brainId || logBrainId;
  const { nodes, index } = selectedMessage;
  const messages = content?.messages.filter(
    (msg) => msg.event === 'message:brain_send'
  );

  const { slug } = useAccount();
  const { collection, uniqueDatasources } = useDetailsSection(
    nodes[index]?.collection,
    messages?.[index]
  );

  const entities = uniqBy(
    (nodes[index]?.messages[0] as UserMessage)?.entities,
    'entity'
  ) as unknown as Entity[];

  return (
    <UnknownDetailsSectionContent
      {...{
        nodes,
        index,
        entities,
        collection,
        uniqueDatasources,
        slug,
        standAloneQuestion,
        messages,
        isReplay,
        canWrite,
        session_id,
        activeBrainId,
      }}
    />
  );
};
