import { useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Button from '@/components/atoms/Button/Button/Button';
import CollectionIcon from '@/components/atoms/Icons/Collection';
import IntentIcon from '@/components/atoms/Icons/Intent';
import SearchIcon from '@/components/atoms/Icons/Search';
import IntentModal from '@/components/organisms/TryIt/IntentModal/IntentModal';
import { useAccount } from '@/hooks/useAccount';
import useBrains from '@/hooks/useBrains';
import { LogSessionContentRow } from '@/hooks/useLogs';
import { Collection } from '@/models/collections';
import { actions } from '@/models/permissions';
import { EventName } from '@/models/segment';
import { RootState } from '@/models/state';
import {
  AmendPayload,
  CollectionMessage,
  Fragment,
  TryIt,
  UserMessage,
} from '@/models/tryIt';
import { LogMessage } from '@/modules/analytics/models';
import { useDetailsSection } from '@/modules/TryIt/hooks/useDetailsSection';
import {
  selectIsTryItReplay,
  selectSelectedMessage,
  selectStandaloneQuestion,
  selectTryItSessionId,
} from '@/modules/TryIt/redux/selectors';
import { popModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectBrainId } from '@/redux/session/selectors';
import { trackEvent } from '@/segment/segment';

import { DocumentLine } from './DocumentLine';
import { StandaloneWrapper } from '../StandaloneWrapper';

import styles from '../DetailsSection.module.scss';

type CollectionDetailsSectionProps = {
  content: LogSessionContentRow;
};

export type CollectionDetailsSectionContentProps = {
  nodes: TryIt['selectedMessage']['nodes'];
  index: number;
  messages: LogMessage[];
  collection: Collection;
  uniqueDatasources: Fragment[];
  slug: string;
  standAloneQuestion: string;
  closeModal: () => void;
  isReplay: boolean;
  canWrite: boolean;
  session_id?: string;
  activeBrainId: string;
};

export const CollectionDetailsSectionContent = ({
  nodes,
  index,
  messages,
  collection,
  uniqueDatasources,
  slug,
  standAloneQuestion,
  closeModal,
  isReplay,
  canWrite,
  session_id,
  activeBrainId,
}: CollectionDetailsSectionContentProps) => {
  const { t } = useTranslation();
  const tryItSessionId = useSelector(selectTryItSessionId);

  const possibleMatchedIntents = (
    nodes[index]?.intents ||
    (messages?.[index]?.message as UserMessage)?.intents
  )?.slice(0, 3);
  const intent =
    (nodes[index]?.messages?.[0] as UserMessage)?.intents?.[0] ||
    nodes[index]?.intents?.[0];
  const showAddTrainingPhrase = isReplay && intent?.confidence !== 1;
  const userMessage = (nodes[index]?.messages[0] as CollectionMessage)?.text;
  const [expressionToTrain, setExpressionToTrain] = useState('');
  const [showIntentsModal, setShowIntentsModal] = useState(false);
  const { brain } = useBrains(activeBrainId);

  const amendPayload: AmendPayload = {
    request_id:
      nodes[index].request_id || nodes[index]?.nodes_stack?.[0]?.request_id,
    task: 'standalone',
    sessionId: session_id || tryItSessionId,
    language: brain?.language,
  };

  return (
    <Stack data-testid="newDebugLogs">
      {isReplay && showIntentsModal && (
        <IntentModal
          close={() => {
            setExpressionToTrain('');
            setShowIntentsModal(false);
          }}
          expression={expressionToTrain}
          brainId={activeBrainId}
        />
      )}

      <StandaloneWrapper
        standAloneQuestion={standAloneQuestion}
        userMessage={userMessage}
        amendPayload={amendPayload}
      />
      <div className={styles.step}>
        <div className={styles.title}>
          <IntentIcon color="var(--icon-default-gray)" />

          <Typography
            variant="body-regular"
            color="var(--text-default-gray)"
            component="div"
          >
            {t('try_it.no_intent_was_matched')}
          </Typography>
        </div>
        <div className={styles.details}>
          {possibleMatchedIntents?.map((intent) => (
            <Typography
              variant="body-regular"
              color="var(--text-default-gray)"
              component="div"
              className={styles.details__content}
              key={intent.intent}
            >
              {t('try_it.possible_match')}
              {': '}
              <Typography variant="body-regular">
                <NavLink
                  to={`/${slug}/brains/${activeBrainId}/intents/${intent.intent}`}
                  className={styles.link}
                  onClick={closeModal}
                >
                  {intent?.intent}
                </NavLink>
              </Typography>{' '}
              {t('try_it.confidence_percentage', {
                percentage: (intent?.confidence * 100).toFixed(0),
              })}
            </Typography>
          ))}
          {showAddTrainingPhrase && (
            <Box ml={1}>
              <Button
                disabled={!canWrite}
                variant="tertiary"
                onClick={() => {
                  setExpressionToTrain(userMessage);
                  setShowIntentsModal(true);
                  trackEvent(EventName.ClickAddTrainingPhrase, { session_id });
                }}
              >
                {t('try_it.details.add_training_phrase')}
              </Button>
            </Box>
          )}
        </div>
      </div>
      <div className={styles.step}>
        <div className={styles.title}>
          <CollectionIcon size={16} />

          <Typography
            variant="body-regular"
            color="var(--text-default-gray)"
            component="div"
          >
            {t('collections.collection')}{' '}
            <NavLink
              target="_blank"
              rel="noopener noreferrer"
              to={`/${slug}/collections/${collection?.collection_id}`}
              className={styles.link}
            >
              {collection?.name}
            </NavLink>{' '}
            {t('try_it.triggered')}
          </Typography>
        </div>
        <div className={styles.details}></div>
      </div>
      <div className={styles.step}>
        <div className={styles.title}>
          <SearchIcon />

          <Typography
            variant="body-regular"
            color="var(--text-default-gray)"
            component="div"
          >
            {t('try_it.document_retrieved', {
              count: uniqueDatasources.length,
            })}
          </Typography>
        </div>
        <div className={styles.details}>
          <ol className={styles.retrievedDocuments}>
            {uniqueDatasources.map((datasource) => (
              <DocumentLine
                key={datasource.document_id}
                collectionId={
                  nodes[index]?.collection?.collection_id ??
                  messages?.[index]?.collection_id
                }
                datasourceId={datasource.datasource_id}
                documentId={datasource.document_id}
              />
            ))}
          </ol>
        </div>
      </div>
    </Stack>
  );
};

export const CollectionDetailsSection = ({
  content,
}: CollectionDetailsSectionProps) => {
  const dispatch = useDispatch();
  const standAloneQuestion = useSelector(selectStandaloneQuestion);
  const selectedMessage = useSelector(selectSelectedMessage);
  const { nodes, index } = selectedMessage;
  const messages = content?.messages.filter(
    (msg) => msg.event === 'message:brain_send'
  );
  const { slug } = useAccount();
  const { collection, uniqueDatasources } = useDetailsSection(
    nodes[index]?.collection,
    messages?.[index]
  );
  const brainId = useSelector(selectBrainId);
  const closeModal = () => {
    dispatch(popModal());
  };
  const isReplay = useSelector(selectIsTryItReplay);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );
  const { session_id, brain_id: logBrainId } = content || {};
  const activeBrainId = brainId || logBrainId;

  return (
    <CollectionDetailsSectionContent
      {...{
        nodes,
        index,
        messages,
        collection,
        uniqueDatasources,
        slug,
        standAloneQuestion,
        closeModal,
        isReplay,
        canWrite,
        session_id,
        activeBrainId,
      }}
    />
  );
};
