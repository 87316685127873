import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { callUpload } from '@/api/fetcher';
import useFileSubmit from '@/components/atoms/FileUpload/useFileSubmit';
import { MODAL_FORM } from '@/components/organisms/Modals/ModalConductor';
import useDocuments from '@/hooks/useDocuments';
import { Document } from '@/models/collections';
import { EventName } from '@/models/segment';
import { addTemporalToast } from '@/modules/notifications/redux/actions';
import { popModal, pushModal } from '@/redux/modals/actions';
import { setDatasourceId } from '@/redux/session/actions';
import { selectAccountId } from '@/redux/session/selectors';
import { trackEvent } from '@/segment/segment';
import { FIFTEEN_MB, HUNDRED_BYTES, FILES_BASE_URL } from '@/util/constants';
import { getRestrictedNames, preventClickThrough } from '@/util/util';

import { generateValidationRules, rulesTXT } from './utils';

export const useDatasource = () => {
  const { t } = useTranslation();
  const { collectionId, datasourceId } = useParams();
  const [urls, setUrls] = useState<
    { url: string; name: string; type?: string }[]
  >([]);
  const [filesLength, setFilesLength] = useState(0);
  const accountId = useSelector(selectAccountId);
  const uploadPath = `${FILES_BASE_URL}/${accountId}/${collectionId}/${datasourceId}`;
  const dispatch = useDispatch();
  const { uploadFile, data } = useFileSubmit({
    stream: true,
    uploadPath,
    parseJson: false,
  });

  const { documents, updateDocument, createDocument, deleteDocument } =
    useDocuments(collectionId, datasourceId);

  useEffect(() => {
    if (data?.url) {
      createDocument(
        {
          type: 'txt',
          name: data.name,
          file_url: data.url,
        },
        {
          onSuccess: () => {
            dispatch(popModal());
          },
        }
      );
    }
  }, [createDocument, data, dispatch]);

  useEffect(() => {
    if (filesLength && filesLength === urls.length) {
      trackEvent(EventName.RunDocumentsUpload, { number: filesLength });
      const createDocuments = async () => {
        await Promise.all(
          urls.map(async (url) => {
            return createDocument({
              type: url.type,
              name: url.name,
              file_url: url.url,
            });
          })
        ).finally(() => {
          setFilesLength(0);
          setUrls([]);
        });
      };
      createDocuments();
    }
  }, [createDocument, deleteDocument, filesLength, urls]);

  const replaceExistingFile = useCallback(
    async (file: File, originalName: string, selectedDocument: Document) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('originalName', originalName);
      const resp = await callUpload(uploadPath, formData);
      updateDocument(
        {
          document_id: selectedDocument?.document_id,
          name: resp.name,
          type: resp?.type,
          file_url: resp.url,
        },
        {
          onSuccess: () => {
            dispatch(popModal());
          },
        }
      );
    },
    [dispatch, updateDocument, uploadPath]
  );

  const handleReplaceFileUploaded = useCallback(
    (e, selectedDocument: Document) => {
      preventClickThrough(e);
      const file = e.target.files[0];
      if (file.size > FIFTEEN_MB) {
        dispatch(
          addTemporalToast(
            'error',
            t('file_upload.fix_too_large', {
              0: `${FIFTEEN_MB / 1000000}`,
            })
          )
        );
        return;
      }
      if (file.size < HUNDRED_BYTES) {
        dispatch(
          addTemporalToast(
            'error',
            t('file_upload.fix_too_small', { 0: `${HUNDRED_BYTES / 1000}` })
          )
        );
        return;
      }
      e.target.value = '';
      const originalName = selectedDocument?.file_url.split('/').pop();

      replaceExistingFile(file, originalName, selectedDocument);
    },
    [dispatch, replaceExistingFile, t]
  );

  const replaceDuplicateFile = useCallback(
    async (file: File, originalName) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('originalName', originalName);
      const document = documents.find((doc) => doc.name === file.name);
      const resp = await callUpload(uploadPath, formData);
      updateDocument(
        {
          document_id: document?.document_id,
          name: resp.name,
          type: resp?.type,
          file_url: resp.url,
        },
        {
          onSuccess: () => {
            dispatch(popModal());
          },
        }
      );
    },
    [dispatch, documents, updateDocument, uploadPath]
  );

  const handleReplaceDuplicateFile = useCallback(
    async (file: File, selectedDocument: Document) => {
      const originalName = selectedDocument?.file_url.split('/').pop();
      await replaceDuplicateFile(file, originalName);
    },
    [replaceDuplicateFile]
  );

  useEffect(() => {
    if (datasourceId) {
      dispatch(setDatasourceId(datasourceId));
    }
    return () => {
      dispatch(setDatasourceId(null));
    };
  }, [dispatch, datasourceId]);

  const onFileCreationClick = (selectedDocument?: Document, body = '') => {
    const originalName = selectedDocument?.file_url.split('/').pop();
    const name = selectedDocument?.name;
    const props = {
      title: `${name ? t('common.edit') : t('common.create')} ${t(
        'collections.documents.document'
      )}`,
      size: 'medium',
      primaryButtonText: t('common.save'),
      mode: 'onSubmit',
      onCreate: async (data: { body: string; document_name: string }) => {
        const documentName = data.document_name;

        const file = new File([data.body], documentName, {
          type: 'text/plain',
        });
        if (!selectedDocument) {
          uploadFile(file);
        } else {
          replaceExistingFile(file, originalName, selectedDocument);
        }
      },
      fields: [
        {
          fieldName: 'document_name',
          fieldValue: name,
          rules: generateValidationRules(getRestrictedNames(documents, name)),
        },
        {
          fieldName: 'body',
          fieldValue: body,
          type: 'textarea',
          rules: rulesTXT.body,
        },
      ],
    };
    dispatch(pushModal(MODAL_FORM, props));
  };

  const handleDocumentToggle = useCallback(
    (id: string) => {
      const foundDocument = documents?.find((doc) => doc.document_id === id);
      updateDocument({
        document_id: foundDocument?.document_id,
        type: foundDocument?.type,
        enabled: !foundDocument?.enabled,
      });
    },
    [documents, updateDocument]
  );

  return {
    handleDocumentToggle,
    onFileCreationClick,
    setFilesLength,
    setUrls,
    handleReplaceFileUploaded,
    handleReplaceDuplicateFile,
  };
};
