import React from 'react';

import MoreHoriz from '@mui/icons-material/MoreHoriz';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import IconButton from '@/components/atoms/IconButton/IconButton';
import { useCopyPaste } from '@/hooks/useCopyPaste';
import useHotkeys from '@/hooks/useHotkeys';
import { TYPES, iconByType } from '@/redux/dialogs/helper';
import { isModalOpen } from '@/redux/modals/selectors';
import { CopiedObject } from '@/util/CopyPasteController';

import { ActionMenu } from '../ActionMenu/ActionMenu';

import styles from './ActionHeader.module.scss';

type Props = {
  type: string;
  onDelete?: () => void;
  children?: React.ReactNode;
  objectToCopy: CopiedObject;
  isSelected: boolean;
  hasDialogError?: boolean;
};

const ActionHeader = ({
  type,
  children,
  onDelete,
  objectToCopy,
  isSelected,
  hasDialogError = false,
}: Props) => {
  const { handlePaste, handleCopy } = useCopyPaste();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const modalOpen = useSelector(isModalOpen);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const hotkeys = {
    'mod+v': handlePaste,
    'mod+c': () => handleCopy(objectToCopy),
    delete: onDelete,
  };

  useHotkeys({ hotkeys, active: isSelected && !modalOpen });

  return (
    <div
      className={cn(styles.header, {
        [styles.condition]: type === 'condition',
      })}
    >
      {!children &&
        iconByType({
          id: type,
          size: 16,
          color: 'var(--icon-default-black)',
          type: TYPES.ACTION,
        })}
      <p>
        {children ?? (
          <Typography variant="label-semi-bold">
            {/* TODO: Simplify below condition before dialog 2.0 deployment */}
            {type === 'text'
              ? t(`actions.types.text_message`)
              : type === 'event'
                ? t(`actions.types.jump_to`)
                : t(`actions.types.${type}`)}
          </Typography>
        )}
      </p>

      <div className={styles.header__icons}>
        {/* TODO: Use proper condition for comments when ready */}
        {/* {true && (
          <IconButton
            ariaLabel={t('dialog.carousel.comments')}
            ariaControls={t('dialog.carousel.comments')}
            ariaHasPopUp
            onClick={() => {}}
          >
            <Comments />
          </IconButton>
        )} */}
        <ActionMenu
          handleClose={handleClose}
          open={open}
          anchorEl={anchorEl}
          handleDelete={onDelete}
          handleCopy={handleCopy}
          handlePaste={handlePaste}
          objectToCopy={objectToCopy}
          disableCopy={hasDialogError}
        />

        <IconButton
          ariaLabel="menu-btn"
          ariaControls="card-menu"
          ariaHasPopUp
          onClick={handleClick}
        >
          <MoreHoriz fontSize="small" />
        </IconButton>
      </div>
    </div>
  );
};

export default ActionHeader;
